<template>
  <div class="container mx-auto px-3 lg:p-0">
    <div class="flex justify-between items-center">
      <h3 class="page-title mb-5">Check Your Payment Status</h3>
      <button
        @click="exportCSV($event)"
        class="main-btn main-btn-lg"
        v-if="isSuccessfull && loads.length > 1"
      >
        <svg class="icon">
          <use xlink:href="#excel"></use></svg
        >Export to excel
      </button>
    </div>
    <div class="factoring-wrapper">
      <div class="lg:flex justify-center">
        <div class="form-group">
          <label>date range#</label>
          <select name="date" id="" class="custom-select" v-model="MonthsBack">
            <option value="0">All</option>
            <option value="1">1 Month</option>
            <option value="3">3 Month</option>
            <option value="6">6 Month</option>
            <option value="12">1 Year</option>
          </select>
        </div>
        <div class="or">OR</div>
        <div class="form-group">
          <label>MC#/USDOT#/DOT</label>
          <input type="text" class="form-control" v-model="MCUSNumber" />
        </div>
        <div class="or">OR</div>
        <div class="form-group mr-2">
          <label>PAYMENT STATUS</label>
          <select
            name="payment"
            id=""
            class="custom-select"
            v-model="PaymentStatus"
          >
            <option value="0">Select Payment Status</option>
            <option value="1">Unapproved</option>
            <option value="2">Payment in Process</option>
            <option value="3">Paid</option>
            <option value="4">Call LDI Corporate</option>
          </select>
        </div>

        <div class="btn-wrapper">
          <button class="main-btn mr-2" @click="getFactoringCompanyDetails">
            <svg class="icon">
              <use xlink:href="#search-icon"></use>
            </svg>
          </button>
          <button class="btn" @click="clear">
            <svg class="icon">
              <use xlink:href="#close-icon"></use>
            </svg>
          </button>
        </div>
      </div>
      <nav
        class="pagination flex items-center justify-between"
        role="navigation"
        aria-label="pagination "
      >
      </nav>
      <div class="table-responsive">
        <DataTable
          :value="loads"
          :paginator="true"
          :rows="10"
          sortMode="multiple"
          v-model:filters="filters1"
          v-if="isSuccessfull"
          ref="dt"
          v-show="!this.loading"
          exportFilename="Factoring Company Data"
          @rowSelect="onRowSelect"
          selectionMode="single"
          dataKey="id"
        >
          <template #header>
            <div class="flex justify-between items-center">
              <div class="no-loads">{{ responseText }}</div>
              <div>
                <span class="p-input-icon-left mr-2">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filters1['global'].value"
                    placeholder="Keyword Search"
                  />
                </span>
                <Button
                  type="button"
                  icon="pi pi-filter-slash"
                  label="Clear"
                  class="p-button-outlined"
                  @click="clearFilter1()"
                />
              </div>
            </div>
          </template>
          <Column field="LoadID" header="Load #" :sortable="true"></Column>
          <Column
            field="PickupDate"
            header="P/U Date"
            :sortable="true"
            dataType="date"
          >
            <template #body="{ data }">
              {{
                data.PickupDate == "0001-01-01T00:00:00"
                  ? null
                  : formatDate(data.PickupDate)
              }}
            </template>
          </Column>
          <Column field="CarrierName" header="Carrier Name" :sortable="true"></Column>
          <Column field="OriginCity" header="Origin" :sortable="true"></Column>
          <Column
            field="DropDate"
            header="Del Date"
            :sortable="true"
            dataType="date"
          >
            <template #body="{ data }">
              {{
                data.DropDate == "0001-01-01T00:00:00"
                  ? null
                  : formatDate(data.DropDate)
              }}
            </template>
          </Column>
          <Column
            field="DestinationCity"
            header="Destination"
            :sortable="true"
          ></Column>
          <Column
            field="ExportedDate"
            header="Date Paperwork Approved"
            :sortable="true"
            dataType="date"
            ><template #body="{ data }">
              {{
                data.ExportedDate == "0001-01-01T00:00:00"
                  ? null
                  : formatDate(data.ExportedDate)
              }}
            </template>
          </Column>
          <Column
            field="PaymentDate"
            header="Expected Pay Date"
            :sortable="true"
            dataType="date"
          >
            <template #body="{ data }">
              {{
                data.PaymentDate == "0001-01-01T00:00:00"
                  ? null
                  : formatDate(data.PaymentDate)
              }}
            </template>
          </Column>
          <Column field="Amount" header="Amount" :sortable="true">
            <template #body="{ data }">
              ${{ data.Amount }}
            </template>
          </Column>
          <Column
            field="PaymentType"
            header="Payment Type"
            :sortable="true"
          ></Column>
          <Column
            field="CheckNumber"
            header="Check #"
            :sortable="true"
          ></Column>
          <Column
            field="Exception"
            header="Exception"
            :sortable="true"
          ></Column>
          <Column
            field="PaymentStatus"
            header="Status"
            :sortable="true"
          ></Column>
        </DataTable>
      </div>
      <div v-if="loading">
        <div class="ribbon">Please wait patiently. We are working on your results.</div>
        <!-- <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton>
        <LoaderSkeleton></LoaderSkeleton> -->
      </div>
    </div>
    <ContactDialogue ref="saveConfirmDialogue"></ContactDialogue>
    <UserAuthorizationPopup ref="openUserPopup"></UserAuthorizationPopup>
  </div>
</template>
<script>
import { dataProvider } from "@/api/dataProvider";
// import LoaderSkeleton from "@/components/common/LoaderSkeleton.vue";
import { FilterMatchMode } from "primevue/api";
import ContactDialogue from "@/components/common/ContactDialogue.vue";
import UserAuthorizationPopup from './UserAuthorizationPopup.vue';
export default {
  data() {
    return {
      filters1: null,
      current: 1,
      pageSize: 10,
      pageCount: null,
      MonthsBack: 0,
      PaymentStatus: 0,
      MCUSNumber: "",
      CarrierID: this.$store.state.login.CarrierID,
      Name: this.$store.state.login.FirstName,
      CompanyName: this.$store.state.login.CompanyName,
      PhoneNumber: this.$store.state.login.PhoneNumber,
      CarrierPortalUserID: this.$store.state.login.CarrierPortalUserID,
      EmailAddress: this.$store.state.login.EmailAddress,
      loads: [],
      isSuccessfull: false,
      loading: false,
      responseText: "",
    };
  },
  components: {
    // LoaderSkeleton,
    ContactDialogue,
    UserAuthorizationPopup,
  },
  created() {
    this.makeAllSortable();
    this.initFilters1();
  },
  methods: {
    formatDate(value) {
      return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    WithoutTime(dateTime) {
      var d = dateTime;
      d = d.split(" ")[0];
      return d;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async getFactoringCompanyDetails() {
      this.loading = true;
      var factoringCompanyDetails =
        await dataProvider.getFactoringCompanyDetails(
          this.MonthsBack,
          this.PaymentStatus,
          this.CarrierID,
          this.MCUSNumber,
          this.Name,
          this.CompanyName,
          this.PhoneNumber,
          this.CarrierPortalUserID,
          this.EmailAddress
        );
      if(factoringCompanyDetails.IsSuccessful == false)
      {
        this.loading = false;
        this.$refs.openUserPopup.show({
        responseText: factoringCompanyDetails.ResponseText,
        UserType: "Factoring Company"
      });
      }
      this.loads = factoringCompanyDetails.Loads;
      this.pageCount = Math.ceil(factoringCompanyDetails.Loads.length / 10);
      this.isSuccessfull = factoringCompanyDetails.IsSuccessful;
      this.responseText = factoringCompanyDetails.ResponseText;
      this.loading = false;
    },
    prev() {
      this.current--;
    },
    next() {
      this.current++;
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
    clear() {
      this.MonthsBack = 0;
      this.MCUSNumber = "";
      this.PaymentStatus = 0;
    },
     onRowSelect() {
      this.$refs.saveConfirmDialogue.show();
    },
    sortTable(table, col, reverse) {
      var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
        tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
        i;
      reverse = -(+reverse || -1);
      tr = tr.sort(function (a, b) {
        // sort rows
        return (
          reverse * // `-1 *` if want opposite order
          a.cells[col].textContent
            .trim() // using `.textContent.trim()` for test
            .localeCompare(b.cells[col].textContent.trim())
        );
      });
      for (i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
    },

    makeSortable(table) {
      var th = table.tHead,
        i;
      th && (th = th.rows[0]) && (th = th.cells);
      if (th) i = th.length;
      else return; // if no `<thead>` then do nothing
      while (--i >= 0)
        (function (i) {
          var dir = 1;
          th[i].addEventListener("click", function () {
            this.sortTable(table, i, (dir = 1 - dir));
          });
        })(i);
    },

    makeAllSortable(parent) {
      parent = parent || document.body;
      var t = parent.getElementsByTagName("table"),
        i = t.length;
      while (--i >= 0) this.makeSortable(t[i]);
    },
  },

  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      return this.loads.slice(this.indexStart, this.indexEnd);
    },
  },
};
</script>
<style lang="scss" scoped>
.factoring-wrapper {
  label {
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 5px;
  }
  .main-btn {
    padding: 10px;
    .icon {
      fill: #fff;
      width: 15px;
      height: 15px;
    }
  }
  .btn {
    padding: 10px;
    .icon {
      width: 15px;
      height: 15px;
    }
  }
  .btn-wrapper {
    margin-top: 26px;
  }
  .or {
    min-width: 40px;
    text-align: center;
    margin-top: 32px;
    color: #181818;
    font-weight: 700;
  }
}

.table-custom {
  width: 100%;
  tr {
    td {
      padding: 6px 8px;
      background: #fff;
      border: 1px solid #ddd;
      word-wrap: break-word;
      text-align: center;
    }
    th {
      padding: 6px 8px;
      background: #f8e3df;
      border: 1px solid #ddd;
      word-wrap: break-word;
      font-size: 14px;
    }
  }
}

.pagination-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  li {
    a {
      color: #fff;
    }
    .main-btn {
      cursor: pointer;
    }
    span.pagination-link {
      border: 1px solid #dbdbdb;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      color: #363636;
      border-radius: 6px;
      min-width: 2.5em;
    }
    .form-control {
      margin-bottom: 0;
    }
    .pagination-btn {
      background-color: #fff;
      border-color: #dbdbdb;
      border-width: 1px;
      color: #363636;
      cursor: pointer;
      justify-content: center;
      padding-bottom: calc(0.5em - 1px);
      padding-left: 1em;
      padding-right: 1em;
      padding-top: calc(0.5em - 1px);
      text-align: center;
      border-radius: 6px;
      margin-left: 10px;
    }
  }
}
.main-btn-lg {
  padding: 10px 30px;
  .icon {
    display: inline-block;
    fill: #fff;
    margin-right: 6px;
  }
}
.load-text {
  font-size: 21px;
  color: #181818;
}
@media (min-width: 992px) {
  .factoring-wrapper {
    .form-control {
      width: 190px;
    }
  }
}
</style>
